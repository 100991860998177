import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";

const BlogPage = ({ data }) => {
  return (
    <Layout nav="blog">
      <h1 className="page-header">
        Blog <small>Archive of blog posts</small>
      </h1>
      <ul className="posts">
        {data.allMdx.edges.map(({ node }) => (
          <li key={node.id}>
            <Link to={`/${node.slug}`}>{node.frontmatter.title}</Link> on{" "}
            {node.frontmatter.date}
          </li>
        ))}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }, limit: 1000) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
          slug
        }
      }
    }
  }
`;

export default BlogPage;
